import { Fragment, useEffect, useState } from "react";
import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
} from "firebase/auth";
import { Transition } from "@headlessui/react";
import { TIMELINE_DATA, TimelineData } from "./timeline";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsLoggedIn(!!user);
    });
    return unsubscribe;
  }, []);

  return isLoggedIn ? <Content /> : <LogIn />;
}

function Content() {
  const [isGiftOpened, setIsGiftOpened] = useState(false);
  const [showText, setShowText] = useState(false);

  const onClick = () => {
    setIsGiftOpened(true);
    setTimeout(() => setShowText(true), 600);
  };

  return (
    <>
      <Transition
        as={Fragment}
        show={!isGiftOpened}
        leave="transform duration-500 transition ease-in-out"
        leaveFrom="opacity-100 scale-100"
        leaveTo="opacity-0 scale-150"
      >
        <div className="h-[100svh] flex flex-col justify-center items-center">
          <div className="cursor-pointer h-8 w-72 text-center">
            <div className="animate-bounce">
              <span className="text-8xl" onClick={onClick}>
                🎁
              </span>
            </div>
          </div>
        </div>
      </Transition>

      <Transition
        as={Fragment}
        show={showText}
        enter="transform duration-200 transition ease-in-out"
        enterFrom="opacity-0 scale-50"
        enterTo="opacity-100 scale-100"
      >
        <div className="flex flex-col justify-center items-center py-8 px-4 bg-gradient-to-b from-pink-200 to-white from-0% to-[2.5%]">
          <ol className="flex flex-col h-full space-y-8">
            <li className="text-5xl font-extrabold text-pink-700/70 text-left">
              Our timeline
            </li>
            {TIMELINE_DATA.map((data, idx) => (
              <TimeNode key={idx} data={data} />
            ))}
          </ol>
        </div>
      </Transition>
    </>
  );
}

function TimeNode({ data }: { data: TimelineData }) {
  return (
    <li className="flex flex-row space-x-8">
      <div className="flex flex-col items-center space-y-8">
        <div className="flex flex-row justify-center items-center">
          <span className="text-3xl ease-in-out duration-300 hover:scale-125 cursor-pointer">
            {data.icon}
          </span>
        </div>
        <span
          className={`w-0.5 bg-gradient-to-b ${data.lineColorFrom} ${data.lineColorTo} h-full`}
        />
      </div>
      <div className="flex flex-col space-y-4 sm:w-96">
        <div className="flex flex-col">
          <h1 className="text-xl font-extrabold h-8">{data.event}</h1>
          <h2 className="text-gray-700 -my-2">{data.date}</h2>
        </div>
        <div className="flex flex-col space-y-1">
          {data.description.map((p, idx) => {
            return (
              <p key={idx} className="text-gray-600 text-sm leading-relaxed">
                {p}
              </p>
            );
          })}
        </div>
        <div className="flex flex-row space-x-4 overflow-x-scroll sm:w-[26rem]">
          {data.imgUrls.map((src, idx) => {
            return (
              <img
                key={idx}
                src={src}
                className="w-10/12 rounded-md shadow-md"
                alt=""
              />
            );
          })}
        </div>
      </div>
    </li>
  );
}

function LogIn() {
  const auth = getAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const onSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setError("");
    } catch (error: any) {
      setError("Incorrect user / password");
    }
  };

  return (
    <main className="flex flex-col justify-center items-center h-[100svh]">
      <form
        className="flex flex-col space-y-2 justify-center items-center relative w-60"
        onSubmit={onSubmit}
      >
        <PinguinAscii />
        <h1 className="text-5xl font-extrabold text-center text-gray-700">
          Pingüinos
        </h1>
        <input
          className="border border-gray-400 rounded-md py-2 px-1 text-center placeholder:text-gray-600 placeholder:text-center bg-white/0 w-full shadow-md"
          placeholder="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <input
          className="border border-gray-400 rounded-md py-2 px-1 text-center placeholder:text-gray-600 placeholder:text-center bg-white/0 w-full shadow-md"
          placeholder="Password"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <button
          type="submit"
          className="border border-orange-400 py-2 px-1 w-full rounded-md shadow-md hover:bg-orange-100 active:bg-orange-200 text-orange-400 font-medium"
        >
          Enter
        </button>
        <span className="text-red-500 block h-4">{error}</span>
      </form>
    </main>
  );
}

// prettier-ignore
function PinguinAscii() {
  return <div className="whitespace-pre font-mono font-bold text-[0.5rem] text-gray-700 cursor-default select-none">
    <p>        ....</p>
    <p>      .::o::::.       .....</p>
    <p>     ::::::::        .::::o:::.</p>
    <p>    .::' `:::        :::::::</p>
    <p>    :::     :       ::'   `.</p>
    <p>   .:::     :      ,:'      :.</p>
    <p>  .:::       :    , :       `::</p>
    <p> .:::        :`. ,  :        :::</p>
    <p>.::::        :  :  ,:        :::</p>
    <p>::::'        :`. :, :        :::</p>
    <p>:: :         :  ""  :        : :</p>
    <p>:  :         :      :        : :</p>
    <p>   :         :      :        :</p>
    <p>   :        :`      :        :</p>
    <p>    : :---: :        : :---: :</p>
    <p>     ```   ```        ```   ```</p>
  </div>
}

export default App;
