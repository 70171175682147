import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyD2lYbqv7PupaBlp0fO5jGieyw73y_3ZpM",
  authDomain: "pinguinos-4fb44.firebaseapp.com",
  projectId: "pinguinos-4fb44",
  storageBucket: "pinguinos-4fb44.appspot.com",
  messagingSenderId: "351712359383",
  appId: "1:351712359383:web:1b1006c81d617fac5b9192",
  measurementId: "G-Y2FDDKV83R",
};
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const analytics = getAnalytics(app);
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const auth = getAuth(app);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
