export interface TimelineData {
  icon: string;
  date: string;
  event: string;
  description: string[];
  imgUrls: string[];
  lineColorFrom?: string;
  lineColorTo?: string;
}

export const TIMELINE_DATA: TimelineData[] = [
  {
    icon: "💯",
    date: "June 2021",
    event: "How it all began",
    description: [
      "Pinguino le react a un story de pinguina bien tranqui. Pinguina le responde con una foto.",
      "Bandida.",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Ff.jpeg?alt=media&token=cf8c49f1-0ffd-4882-8a39-aa6931461daf",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fe.jpeg?alt=media&token=4bbb592e-2e58-4b7c-8194-4a946c71ed6f",
    ],
    lineColorFrom: "from-gray-200",
    lineColorTo: "to-blue-100",
  },
  {
    icon: "🕵🏻",
    date: "July 2021",
    event: "Have you seen my pinguin?",
    description: [
      "Después de estár hablando mucho mucho, pinguino se desapareció por 3 días 👻.",
      "Cuando resucitó, pinguino subió una indirecta muy directa a instagram pero la pinguina lo ignoró!",
      "A pinguino le tocó tragarse su orgullo y escribirle a pinguina directamente...",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Ffiona.jpg?alt=media&token=a6d14daa-80a0-4ce8-a196-e59032fcc8ea",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fshrek%20chat.png?alt=media&token=6ead6fba-086f-4360-8cc1-f97acce267a9",
    ],
    lineColorFrom: "from-blue-100",
    lineColorTo: "to-blue-200",
  },
  {
    icon: "🫶🏼",
    date: "August 2021",
    event: "Catching feels",
    description: [
      "La pinguina confiesa que está comenzando a sentir cositas y tiene sustico.",
      "Qué tal que el pinguino sea solo un f*ck boy?",
      "Fortunately, pinguino tambien estaba empezando a catch feels!",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fline%20graph.jpeg?alt=media&token=f5a56235-f55d-4c88-a82b-3f41fe3df412",
    ],
    lineColorFrom: "from-blue-200",
    lineColorTo: "to-blue-300",
  },
  {
    icon: "👩🏼‍❤️‍👨🏻",
    date: "March 2022",
    event: "Te amo",
    description: [
      "Nos conocimos! Nos conocimos! Nos conocimos! Y al final a pinguino se le escapó un te amo 🤭.",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fa.jpeg?alt=media&token=03c570d5-8a85-4dcd-a09c-39023514771d",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Ffirst%20trip%20med%202.jpeg?alt=media&token=9567aa8e-121e-4f44-9502-afe6773e7603",
    ],
    lineColorFrom: "from-blue-300",
    lineColorTo: "to-pink-100",
  },
  {
    icon: "🎣",
    date: "April 2022",
    event: "Officially a couple",
    description: [
      "La pinguina fue importada a Seattle desde Medellin (como la Mozarella di Buffala de Whole Foods).",
      "Después de un descache, pinguino cae en razón y le pide a pinguina que sean novios.",
      "Gracias por tenerle paciencia a pinguino, pinguina 🥰.",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea1%201.jpeg?alt=media&token=0dc06ce8-437e-418c-845c-78c084c468db",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea1%202.jpeg?alt=media&token=23b8804c-4d99-4298-8ac6-41d0030f2c42",
    ],
    lineColorFrom: "from-pink-100",
    lineColorTo: "to-pink-200",
  },
  {
    icon: "🦶🏼",
    date: "July 2022",
    event: "La mejor enfermera",
    description: [
      "La pinguina fue nuevamente importada a Seattle, y acompañó a pinguino en uno de los momentos mas scary de su vida.",
      "Gracias. Gracias. Gracias.",
      "❤️❤️❤️",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea2%204.jpeg?alt=media&token=91416fe0-90fa-4f69-947f-509a12079c86",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea2%203.jpeg?alt=media&token=09c0e3c5-4ff1-4561-a014-ff4aa86c9128",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea2%201.jpeg?alt=media&token=cbfb6130-f28f-4ce7-9343-0b4830ab9ecd",
    ],
    lineColorFrom: "from-pink-200",
    lineColorTo: "to-pink-300",
  },
  {
    icon: "🍁",
    date: "September 2022",
    event: "SF & SEA!",
    description: [
      "La pinguina fue importada por tercera vez y vivió su primer otoño con el pinguino.",
      'Pinguino remó por primera vez, y le pareció "brutal"!',
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea3%201.jpeg?alt=media&token=a4cb3f94-58ae-4fb5-98cb-2a0c62a31317",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea3%202.jpeg?alt=media&token=ba2aa9c6-ea9b-4831-84ec-2ba66087004a",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea3%203.jpeg?alt=media&token=113c2d97-f285-4919-8815-7c75623b66c2",
    ],
    lineColorFrom: "from-pink-300",
    lineColorTo: "to-pink-400",
  },
  {
    icon: "🐈",
    date: "December 2022",
    event: "Gatitos",
    description: [
      "Los pinguinos conocieron a todos los gatitos... pero no hay foticos de todos juntitos.",
      "Pinguinos tambien pasaron su primer new years juntos!",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fcol%20dec%202022%201.jpeg?alt=media&token=72f70e2b-43b5-4f83-95da-3f32e3a83fa0",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fcol%20dec%202022%202.jpeg?alt=media&token=66a743ec-dcd8-49ea-8199-6fe862baf38a",
    ],
    lineColorFrom: "from-pink-400",
    lineColorTo: "to-pink-500",
  },
  {
    icon: "👩🏼‍🎓",
    date: "April 2023",
    event: "Pinguina graduada",
    description: ["Ahora tenemos una pinguina ingeniera! Muy linda."],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fgrad%201.jpeg?alt=media&token=b2285075-8439-492e-8716-29e64c31e157",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fgrad%202.jpeg?alt=media&token=4fcf2bcb-cc2a-4ae4-b304-af25a49d6f8d",
    ],
    lineColorFrom: "from-pink-500",
    lineColorTo: "to-pink-600",
  },
  {
    icon: "🦒",
    date: "July 2023",
    event: "30 días! Juntos!",
    description: [
      "La pinguina dura un mes entero en Seattle con el pinguino. Y lo termina acompañando en momentos muy muy duros...",
      "Pero estando ahí con él crean muchos momentos lindos.",
    ],
    imgUrls: [
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea4%201.jpeg?alt=media&token=bc0f9f88-48aa-49b8-8d68-3d9ff3f9bcaa",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea4%202.jpeg?alt=media&token=607ee6eb-b313-40ef-a194-604339b20977",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea4%203.jpeg?alt=media&token=52bc4b4f-fb4f-4d45-89e2-de3439654e3b",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea4%205.jpeg?alt=media&token=0780fcaf-996d-4950-b471-f625dc4fc9e5",
      "https://firebasestorage.googleapis.com/v0/b/pinguinos-4fb44.appspot.com/o/images%2Fsea4%204.jpeg?alt=media&token=27e705be-d4bc-492f-b1f9-5db92cda21fc",
    ],
    lineColorFrom: "from-pink-600",
    lineColorTo: "to-red-700",
  },
  {
    icon: "🎂",
    date: "September 2023",
    event: "23",
    description: [
      "Pinguina cumple años y pinguino le escribe una carta:",
      '"Feliz cumpleaños mi carito. Otra vez estamos a 4 añitos de distancia y no me siento tan viejo.',
      "Me encanta verte toda feliz en un nuevo país, haciendo amigos nuevos, conociendo mucho mucho mucho... ",
      "Me emociona que ya pronto estaremos juntitos ❤️. But for now, I'm happy that you're in the right place, doing you, shining a light.",
      'Te amo."',
    ],
    imgUrls: [],
    lineColorFrom: "from-pink-600",
    lineColorTo: "to-red-700",
  },
];
